import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import { isEqual } from "lodash";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import { GenericWavefront } from "components/GenericWavefront/GenericWavefront";
import { GenericImageWavefront } from "components/GenericImageWavefront/GenericImageWavefront";
import AnalysisApi from "MetaComponent/api/Analysis";
import Wavefront from "MetaComponent/containers/TargetCanvas/components/Wavefront/Wavefront";
import Spinner from "components/Spinner/Spinner";
import Efficiency from "./components/Efficiency/Efficiency";
import SweepPoint from "components/SweepPoint/SweepPoint";
import DirectionSnackbar from "components/Snackbar/Snackbar";

export const styles = {
  root: {
    marginBottom: 40,
    paddingRight: 260
  },
  title: {
    textAlign: "center"
  },
  centerElement: {
    display: "block",
    margin: "auto"
  }
};

export class AnalysisJobResult extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sweptVariableValues: {},
      ffwf: null,
      ffwf_image: null,
      nfwf: null,
      nfwf_phase_image: null,
      nfwf_amplitude_image: null,
      fourier: null,
      fourier_image: null,
      loadingResult: false,
      ffwfWidth: null,
      ffwfHeight: null,
      ffEfficiency: null,
      nfEfficiency: null,
      fourrierEfficiency: null,
      rows_count: null,
      columns_count: null,
      ffwf_cross_section_orientation: "xz",
      ffwf_cross_section_index: null,
      nfwf_cross_section_orientation: "xz",
      nfwf_cross_section_index: null,
      nfwf_cross_section_type: "Amplitude",
      fourier_cross_section_orientation: "xz",
      fourier_cross_section_index: null
    };
  }

  componentDidMount() {
    this.setSelectedValues();
    this.getResult();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.sweptVariables, this.props.sweptVariables)) {
      this.setSelectedValues();
    }
  }

  /**
   * sets in the state the first value of each swept variable
   */
  setSelectedValues() {
    let sweptVariableValues = {};
    for (const variable of this.props.sweptVariables) {
      sweptVariableValues[variable.name] = variable.values[0];
    }
    this.setState({ sweptVariableValues });
  }

  /**
   * it updates a variable's selected value in the state
   * @param {*} sweptVariableValues - the updated variable values
   */
  setSelectedValue = sweptVariableValues => {
    this.setState({ sweptVariableValues }, this.getResult);
  };

  /**
   * @param {String} params - the start, stop and step parameters divided by separators
   * @returns {Number[]} - an array containing all parameters
   */
  splitVariableParams = params => {
    const splittedParameters = params.split(/,|;|:/);
    return splittedParameters.map(parameter => parseFloat(parameter));
  };

  ifGeneralSweepUsedParameter = sv => {
    const { outgoingConditions, setPoint } = this.props;
    const order_out_x = outgoingConditions.order_out[0],
      order_out_y = outgoingConditions.order_out[1],
      diffractive_order_x = setPoint.diffractive_order["X"],
      diffractive_order_y = setPoint.diffractive_order["Y"],
      {
        amplitude,
        azimut,
        beam_divergence,
        focal_spot_x,
        focal_spot_y,
        focal_spot_z,
        incident_light_type,
        wavelength,
        zenit
      } = setPoint.incident_light,
      general_parameters = [
        zenit,
        azimut,
        wavelength,
        amplitude,
        diffractive_order_x,
        diffractive_order_y,
        order_out_x,
        order_out_y
      ],
      gb_parameters = [
        beam_divergence,
        focal_spot_x,
        focal_spot_y,
        focal_spot_z
      ];
    if (incident_light_type == "PW") {
      return general_parameters.some(
        param =>
          param?.toString().includes("=") &&
          param.toString().split("=")[1] == sv.name
      );
    } else {
      // GB
      return (
        general_parameters.some(
          param =>
            param?.toString().includes("=") &&
            param.toString().split("=")[1] == sv.name
        ) ||
        gb_parameters.some(
          param =>
            param?.toString().includes("=") &&
            param.toString().split("=")[1] == sv.name
        )
      );
    }
  };

  isFFWFParameter = sv => {
    const {
      ffwfDx,
      ffwfDy,
      ffwfDz,
      columns_count,
      rows_count,
      ffwfHeight,
      ffwfWidth
    } = this.props;
    const ffwfParameters = [
      ffwfDx,
      ffwfDy,
      ffwfDz,
      columns_count,
      rows_count,
      ffwfHeight,
      ffwfWidth
    ];
    return ffwfParameters.some(
      param =>
        param?.toString().includes("=") &&
        param.toString().split("=")[1] == sv.name
    );
  };

  /**
   * it shows a spinner, gets the result and hides the spinner again.
   * farfield wavefront has to be wrapped in a matrix because the wavefront component expects
   * 2 matrices.
   */
  getResult = async () => {
    this.setState({
      loadingResult: true
    });
    const result = await AnalysisApi.getAnalysisJobResult(
        this.props.jobId,
        this.state.sweptVariableValues
      ),
      {
        ffwf,
        nfwf,
        fourier,
        width,
        height,
        ff_efficiency,
        nf_efficiency,
        fourier_efficiency,
        rows_count,
        columns_count,
        fourier_theta_angles,
        ffwf_image,
        nfwf_phase_image,
        nfwf_amplitude_image,
        fourier_image,
        nfwf_shape,
        ffwf_shape,
        fourier_shape
      } = result.results[0];
    let newState = {
      loadingResult: false,
      nfwf,
      ffwfWidth: width,
      ffwfHeight: height,
      nfEfficiency: nf_efficiency,
      rows_count,
      columns_count,
      fourier_theta_angles,
      ffwf_image,
      nfwf_amplitude_image,
      fourier_image,
      nfwf_shape,
      ffwf_shape,
      fourier_shape
    };
    if (nfwf_phase_image) {
      newState.nfwf_phase_image = new Blob([nfwf_phase_image], {
        type: "image/png"
      });
    }

    if (nfwf_shape) {
      newState.nfwf_shape = JSON.parse(nfwf_shape).splice(1);
    }

    if (ffwf_shape) {
      newState.ffwf_shape = JSON.parse(ffwf_shape);
    }

    if (fourier_shape) {
      newState.fourier_shape = JSON.parse(fourier_shape);
    }

    if (nfwf_amplitude_image) {
      newState.nfwf_amplitude_image = new Blob([nfwf_amplitude_image], {
        type: "image/png"
      });
    }

    if (ffwf_image) {
      newState.ffwf_image = new Blob([ffwf_image], {
        type: "image/png"
      });
    }

    if (fourier_image) {
      newState.fourier_image = new Blob([fourier_image], {
        type: "image/png"
      });
    }

    newState.fourier = fourier
      ? [fourier, ["fake phase because it is not implemented for fourier"]]
      : null;
    newState.ffwf = ffwf
      ? [ffwf, ["fake phase because it is not implemented for ffwf"]]
      : null;
    newState.ffEfficiency =
      ff_efficiency !== null && ff_efficiency !== undefined
        ? ff_efficiency
        : null;
    newState.fourrierEfficiency =
      fourier_efficiency !== null && fourier_efficiency !== undefined
        ? fourier_efficiency
        : null;
    this.setState(newState);
  };

  /**
   * it shows a spinner, gets the cross section result and hides the spinner again.
   */
  getFfwfCrossSectionData = async (
    cross_section_orientation = "xz",
    cross_section_index = 0
  ) => {
    this.setState({
      loadingResult: true,
      ffwf_cross_section_orientation: cross_section_orientation,
      ffwf_cross_section_index: cross_section_index
    });

    let crossSectionSweptVariables = this.state.sweptVariableValues;
    const isDzSwept = this.props.ffwfDz?.toString()[0] === "=";
    if (isDzSwept) {
      crossSectionSweptVariables = Object.fromEntries(
        Object.entries(crossSectionSweptVariables).filter(
          ([key]) => key !== this.props.ffwfDz?.toString().substring(1)
        )
      );
    }
    const result = await AnalysisApi.getAnalysisCrossSectionData(
      this.props.jobId,
      crossSectionSweptVariables,
      "ffwf",
      cross_section_orientation,
      cross_section_index
    );
    let newState = {
      loadingResult: false
    };
    this.setState(newState);
    return result?.data;
  };

  getFourierWFCrossSectionData = async (
    cross_section_orientation = "xz",
    cross_section_index = 0
  ) => {
    this.setState({
      loadingResult: true,
      fourier_cross_section_orientation: cross_section_orientation,
      fourier_cross_section_index: cross_section_index
    });

    let crossSectionSweptVariables = this.state.sweptVariableValues;
    const result = await AnalysisApi.getAnalysisCrossSectionData(
      this.props.jobId,
      crossSectionSweptVariables,
      "fourier",
      cross_section_orientation,
      cross_section_index
    );
    let newState = {
      loadingResult: false
    };
    this.setState(newState);
    return result?.data;
  };

  setNfwfCrossSectionType = nfwf_cross_section_type => {
    this.setState({
      nfwf_cross_section_type
    });
  };

  /**
   * it shows a spinner, gets the cross section result and hides the spinner again.
   */
  getNfwfCrossSectionData = async (
    cross_section_orientation = "xz",
    cross_section_index = 0,
    nfwf_cross_section_type
  ) => {
    this.setState({
      loadingResult: true,
      nfwf_cross_section_orientation: cross_section_orientation,
      nfwf_cross_section_index: cross_section_index
    });
    let crossSectionSweptVariables = this.state.sweptVariableValues;
    crossSectionSweptVariables = Object.fromEntries(
      Object.entries(crossSectionSweptVariables).filter(
        ([key]) =>
          !this.isFFWFParameter({ name: key }) ||
          this.ifGeneralSweepUsedParameter({ name: key })
      )
    );
    const result = await AnalysisApi.getAnalysisCrossSectionData(
      this.props.jobId,
      crossSectionSweptVariables,
      "nfwf",
      cross_section_orientation,
      cross_section_index,
      (nfwf_cross_section_type =
        nfwf_cross_section_type ?? this.state.nfwf_cross_section_type)
    );
    let newState = {
      loadingResult: false
    };
    this.setState(newState);
    return result?.data;
  };

  /**
   * @returns {Boolean} whether variables were used in the analysis
   */
  isMultipleResult() {
    return this.props.sweptVariables.length > 0;
  }

  getFFWFCoordinate(coordinate) {
    if (isNaN(coordinate)) {
      const variableName = coordinate.substring(1);
      return this.state.sweptVariableValues[variableName];
    }
    return parseFloat(coordinate);
  }

  getXCenter() {
    const { ffwfDx } = this.props;
    return this.getFFWFCoordinate(ffwfDx);
  }

  getFourierXCenter() {
    const { angular_center_angle_x } = this.props;
    return this.getFFWFCoordinate(angular_center_angle_x);
  }

  getYCenter() {
    const { ffwfDy } = this.props;
    return this.getFFWFCoordinate(ffwfDy);
  }

  getFourierYCenter() {
    const { angular_center_angle_y } = this.props;
    return this.getFFWFCoordinate(angular_center_angle_y);
  }

  getFourierOpeningAngleX() {
    const { opening_angle_x } = this.props;
    return this.getFFWFCoordinate(opening_angle_x);
  }

  getFourierOpeningAngleY() {
    const { opening_angle_y } = this.props;
    return this.getFFWFCoordinate(opening_angle_y);
  }

  render() {
    const {
        classes,
        sweptVariables,
        nfwfWidth,
        nfwfHeight,
        nfwfUnit,
        angularUnit
      } = this.props,
      {
        ffwf,
        nfwf,
        fourier,
        loadingResult,
        ffwfHeight,
        ffwfWidth,
        ffEfficiency,
        nfEfficiency,
        fourrierEfficiency,
        rows_count,
        columns_count,
        fourier_theta_angles,
        ffwf_cross_section_orientation,
        ffwf_cross_section_index,
        nfwf_cross_section_orientation,
        nfwf_cross_section_index,
        fourier_cross_section_orientation,
        fourier_cross_section_index,
        nfwf_phase_image,
        nfwf_amplitude_image,
        fourier_image,
        ffwf_image,
        ffwf_shape,
        nfwf_shape,
        fourier_shape
      } = this.state,
      singleWavefront =
        ((ffwf || ffwf_image) &&
          !(nfwf || nfwf_phase_image || nfwf_amplitude_image) &&
          !(fourier || fourier_image)) ||
        (!(ffwf || ffwf_image) &&
          (nfwf || nfwf_phase_image || nfwf_amplitude_image) &&
          !(fourier || fourier_image)) ||
        (!(ffwf || ffwf_image) &&
          !(nfwf || nfwf_phase_image || nfwf_amplitude_image) &&
          (fourier || fourier_image)),
      centerGridStyle = { margin: "auto" };

    return (
      <div className={classes.root}>
        <Typography className={classes.title} variant="h5" component="h3">
          {"Result"}
        </Typography>

        <Grid container spacing={2} style={{ marginTop: 20 }}>
          <Grid item xs={5}>
            <Grid container style={{ width: "60%", margin: "auto" }}>
              {this.isMultipleResult() && (
                <Grid item xs={12}>
                  <SweepPoint
                    sweptVariables={sweptVariables.filter(sv => {
                      return !ffwf
                        ? this.isFFWFParameter(sv) &&
                          !this.ifGeneralSweepUsedParameter(sv)
                          ? false
                          : true
                        : true;
                    })}
                    sweptVariableValues={this.state.sweptVariableValues}
                    setSelectedValue={this.setSelectedValue}
                    onChangeCommitted={() => {
                      this.getResult();
                      // this.getFfwfCrossSectionData();
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                {loadingResult && (
                  <Spinner
                    size={25}
                    className={classes.centerElement}
                    timeout={30000}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Efficiency
                  efficiency={nfEfficiency}
                  type={"NFWF"}
                  test-data={"nfEfficiency"}
                />
              </Grid>
              <Grid item xs={12}>
                <Efficiency
                  efficiency={ffEfficiency}
                  type={"FFWF"}
                  test-data={"ffEfficiency"}
                />
              </Grid>
              <Grid item xs={12}>
                <Efficiency
                  efficiency={fourrierEfficiency}
                  type={"Fourier"}
                  test-data={"fourrierEfficiency"}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {fourier && (
                <Grid
                  item
                  xs={singleWavefront ? 8 : 4}
                  style={singleWavefront ? centerGridStyle : null}
                  test-data={"fourier"}
                >
                  <GenericWavefront
                    title={"Fourier Wavefront"}
                    hidePhase
                    wavefront={fourier}
                    wfWidth={this.getFourierOpeningAngleX()}
                    wfHeight={this.getFourierOpeningAngleY()}
                    showLegend
                    enableCrossSection
                    rowsCount={fourier_theta_angles["X"].length}
                    columnsCount={fourier_theta_angles["Y"].length}
                    crossSectionOrientation={fourier_cross_section_orientation}
                    crossSectionIndex={fourier_cross_section_index}
                    handleCrossSection={this.getFourierWFCrossSectionData}
                    hideHeatMapCrossSectionOrientationLabel={true}
                    xLabel={`x-angle (${angularUnit})`}
                    wavefrontXData={fourier_theta_angles["X"]}
                    wavefrontYData={fourier_theta_angles["Y"]}
                    yLabel={`y-angle (${angularUnit})`}
                    xCenter={this.getFourierXCenter()}
                    yCenter={this.getFourierYCenter()}
                  />
                </Grid>
              )}
              {nfwf_phase_image &&
                nfwf_amplitude_image &&
                ffwf_image &&
                !fourier &&
                (!fourier_image || !fourier_shape) && (
                  <DirectionSnackbar
                    message={
                      "Fourier analysis image unavailable, redo the analysis to generate the image"
                    }
                  />
                )}
              {!fourier && fourier_image && fourier_shape && (
                <Grid
                  item
                  xs={singleWavefront ? 8 : 4}
                  style={singleWavefront ? centerGridStyle : null}
                  test-data={"fourierImage"}
                >
                  <GenericImageWavefront
                    title={"Fourier Wavefront"}
                    hidePhase
                    wavefrontImages={[
                      fourier_image,
                      "fake phase because it is not implemented for fourier"
                    ]}
                    wfWidth={this.getFourierOpeningAngleX()}
                    wfHeight={this.getFourierOpeningAngleY()}
                    showLegend
                    enableCrossSection
                    rowsCount={fourier_theta_angles["X"].length}
                    columnsCount={fourier_theta_angles["Y"].length}
                    crossSectionOrientation={fourier_cross_section_orientation}
                    crossSectionIndex={fourier_cross_section_index}
                    handleCrossSection={this.getFourierWFCrossSectionData}
                    hideHeatMapCrossSectionOrientationLabel={true}
                    // xLabel={`x-angle (${angularUnit})`}
                    // wavefrontXData={fourier_theta_angles["X"]}
                    // wavefrontYData={fourier_theta_angles["Y"]}
                    // yLabel={`y-angle (${angularUnit})`}
                    xCenter={this.getFourierXCenter()}
                    yCenter={this.getFourierYCenter()}
                    wavefront_shape={fourier_shape}
                  />
                </Grid>
              )}
              {ffwf && (
                <Grid
                  item
                  xs={singleWavefront ? 8 : 4}
                  style={singleWavefront ? centerGridStyle : null}
                  test-data={"ffwf"}
                >
                  <GenericWavefront
                    title={"Far-field wavefront"}
                    hidePhase
                    wavefront={ffwf}
                    wfWidth={ffwfWidth}
                    wfHeight={ffwfHeight}
                    xCenter={this.getXCenter()}
                    yCenter={this.getYCenter()}
                    unit={this.props.ffwfUnit}
                    showLegend
                    enableCrossSection
                    // rowsCount={rows_count}
                    // columnsCount={columns_count}
                    crossSectionOrientation={ffwf_cross_section_orientation}
                    crossSectionIndex={ffwf_cross_section_index}
                    handleCrossSection={this.getFfwfCrossSectionData}
                  />
                </Grid>
              )}
              {!ffwf && ffwf_image && ffwf_shape && (
                <Grid
                  item
                  xs={singleWavefront ? 8 : 4}
                  style={singleWavefront ? centerGridStyle : null}
                  test-data={"ffwfImage"}
                >
                  <GenericImageWavefront
                    title={"Far-Field Wavefront"}
                    hidePhase
                    wavefrontImages={[
                      ffwf_image,
                      "fake phase because it is not implemented for ffwf"
                    ]}
                    wfWidth={ffwfWidth}
                    wfHeight={ffwfHeight}
                    unit={this.props.ffwfUnit}
                    showLegend
                    enableCrossSection
                    crossSectionOrientation={ffwf_cross_section_orientation}
                    crossSectionIndex={ffwf_cross_section_index}
                    handleCrossSection={this.getFfwfCrossSectionData}
                    hideHeatMapCrossSectionOrientationLabel={true}
                    // xLabel={`x-angle (${angularUnit})`}
                    // wavefrontXData={fourier_theta_angles["X"]}
                    // wavefrontYData={fourier_theta_angles["Y"]}
                    // yLabel={`y-angle (${angularUnit})`}
                    xCenter={this.getXCenter()}
                    yCenter={this.getYCenter()}
                    wavefront_shape={ffwf_shape}
                  />
                </Grid>
              )}
              {ffwf && nfwf && (
                <Grid
                  item
                  xs={singleWavefront ? 8 : 4}
                  style={singleWavefront ? centerGridStyle : null}
                  test-data={"nfwf"}
                >
                  <GenericWavefront
                    title={"Near-field wavefront"}
                    wavefront={nfwf}
                    wfWidth={nfwfWidth}
                    wfHeight={nfwfHeight}
                    enableCrossSection
                    // rowsCount={nfwfWidth}
                    // columnsCount={nfwfHeight}
                    crossSectionOrientation={nfwf_cross_section_orientation}
                    crossSectionIndex={nfwf_cross_section_index}
                    handleCrossSection={this.getNfwfCrossSectionData}
                    onWaveFrontSelect={this.setNfwfCrossSectionType}
                    unit={nfwfUnit}
                    showLegend
                  />
                </Grid>
              )}
              {!nfwf && nfwf_phase_image && nfwf_amplitude_image && nfwf_shape && (
                <Grid
                  item
                  xs={singleWavefront ? 8 : 4}
                  style={singleWavefront ? centerGridStyle : null}
                  test-data={"nfwfImage"}
                >
                  <GenericImageWavefront
                    title={"Near-field wavefront"}
                    wavefrontImages={[nfwf_amplitude_image, nfwf_phase_image]}
                    wfWidth={nfwfWidth}
                    wfHeight={nfwfHeight}
                    enableCrossSection
                    // rowsCount={nfwfWidth}
                    // columnsCount={nfwfHeight}
                    crossSectionOrientation={nfwf_cross_section_orientation}
                    crossSectionIndex={nfwf_cross_section_index}
                    handleCrossSection={this.getNfwfCrossSectionData}
                    onWaveFrontSelect={this.setNfwfCrossSectionType}
                    unit={nfwfUnit}
                    showLegend
                    wavefront_shape={nfwf_shape}
                  />
                </Grid>
              )}
              {!ffwf && nfwf && (
                <Wavefront
                  wavefront={nfwf}
                  wfWidth={nfwfWidth * (1 - 1 / nfwf[1][1].length)} //width is full component, but visualized at center of component
                  wfHeight={nfwfHeight * (1 - 1 / nfwf[1].length)}
                  unit={nfwfUnit}
                  enableCrossSection
                  // rowsCount={nfwfWidth}
                  // columnsCount={nfwfHeight}
                  crossSectionOrientation={nfwf_cross_section_orientation}
                  crossSectionIndex={nfwf_cross_section_index}
                  handleCrossSection={this.getNfwfCrossSectionData}
                  showLegend
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        {nfEfficiency !== null &&
          nfEfficiency !== undefined &&
          nfEfficiency > 1 && (
            <DirectionSnackbar
              message={
                "The near-field efficiency calculation shows optical gain.\nCheck the meta-cell definition to make sure this is intended."
              }
            />
          )}
        {ffEfficiency !== null &&
          ffEfficiency !== undefined &&
          nfEfficiency !== null &&
          nfEfficiency !== undefined &&
          ffEfficiency > nfEfficiency && (
            <DirectionSnackbar
              message={
                "The far field calculation is not well resolved and the calculated\nFFWF efficiency and Intensity may be inaccurate.\nConsider the following to improve accuracy:\n\t1) Increase the number of pixels\n\t2) Set an odd number of pixels\n\t3) Decrease the size of the far-field"
              }
            />
          )}
      </div>
    );
  }
}

export default withErrorBoundary(withStyles(styles)(AnalysisJobResult));
