import {
  Grid,
  TextField,
  withStyles,
  Paper,
  Typography,
  LinearProgress,
  Button
} from "@material-ui/core";
import { MetaComponentPaths } from "MetaComponent/MetaComponent";
import React, { PureComponent } from "react";
import DesignJobDropdown from "MetaComponent/containers/DesignCanvas/components/DesignJobDropdown/DesignJobDropdown";
import OdaJobDropdown from "./components/OdaJobDropdown";
import ODAForm from "./components/ODAForm";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import DesignSelector from "MetaComponent/selectors/Design";
import ODASelector from "MetaComponent/selectors/ODA";
import { connect } from "react-redux";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import JobActions from "components/JobActions/JobActions";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import JsonDialog from "components/JsonDialog/JsonDialog";
import DesignAction from "MetaComponent/actions/Design";
import DesignApi from "MetaComponent/api/Design";
import OdaApi from "MetaComponent/api/ODA";
import debounce from "lodash.debounce";
import OdaJobResult from "./components/OdaJobResult";
import OdaAction from "MetaComponent/actions/ODA";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import Spinner from "components/Spinner/Spinner";

export const styles = theme => ({
  right: {
    display: "flex",
    flexDirection: "column",
    width: "200px",
    textAlign: "center",
    padding: "0 30px",
    position: "fixed",
    right: 0,
    top: 150
  },
  left: {
    paddingRight: 260
  },
  nameInput: {
    marginLeft: 10
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  wrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    paddingTop: 50
  },
  progress: {
    maxWidth: 500,
    margin: "auto",
    textAlign: "center",
    width: "100%"
  }
});

const OdaJobSubmissionMode = Object.freeze({
  CREATE: "create",
  UPDATE: "update"
});

export class ODACanvas extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      odaName: this.getDefaultODAName(),
      jsonToShow: null,
      // polling: true,
      message: null,
      valueSubmissionIsReady: false,
      defaultValues: null
    };
    this.odaFormSubmitter = null;
  }

  componentDidMount() {
    this.props.setPage(MetaComponentPaths.ODA);
    this.init();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedOdaJobId, selectedDesignJobId } = this.props;
    this.submitOdaIfReady(prevState);
    const odaJobChanged = prevProps.selectedOdaJobId !== selectedOdaJobId,
      designJobChanged = prevProps.selectedDesignJobId !== selectedDesignJobId;
    if (odaJobChanged || designJobChanged) {
      this.init();
    }
  }

  handlePermissionError(response) {
    if (response.status === 403) {
      this.setState({ polling: false, message: null }, () =>
        this.setState({ message: response.data.detail })
      );
    } else if (response.status === 503) {
      this.setState({ polling: false, message: null });
    } else {
      this.setState({ polling: false, message: response.data?.message });
    }
  }

  getDataToSubmit() {
    const { selectedDesignJobId } = this.props,
      { odaName, dataToSubmit } = this.state;
    return {
      ...dataToSubmit,
      set_point_unit: dataToSubmit.unit,
      swept_variables: this.getTempVariables(),
      design_job: selectedDesignJobId,
      name: odaName ? odaName : this.getDefaultODAName()
    };
  }

  createNewOdaJob = async () => {
    const { createOdaJob, selectOdaJob } = this.props;
    try {
      const id = await createOdaJob(this.getDataToSubmit());
      selectOdaJob(id);
    } catch (e) {
      this.handlePermissionError(e.response);
    }
    return;
  };

  submitOdaIfReady(prevState) {
    const { valueSubmissionIsReady, submissionMode } = this.state;
    if (prevState.valueSubmissionIsReady !== valueSubmissionIsReady) {
      if (valueSubmissionIsReady) {
        this.setState(
          {
            valueSubmissionIsReady: false,
            polling: true
          },
          () => {
            if (submissionMode === OdaJobSubmissionMode.CREATE) {
              this.createNewOdaJob();
            } else if (submissionMode === OdaJobSubmissionMode.UPDATE) {
              this.updateExistingOdaJob();
            }
          }
        );
      }
    }
  }

  updateExistingOdaJob = async () => {
    const { updateOdaJob, selectedOdaJobId } = this.props;
    try {
      await updateOdaJob(selectedOdaJobId, this.getDataToSubmit());
      this.getOdaJobProgress();
    } catch (e) {
      console.log("oda update existing error", e);
      this.handlePermissionError(e.response);
    }
    return;
  };

  async init() {
    const { odaJobs, selectedOdaJobId } = this.props;
    const defaultValues = await this.getdefaultValues();
    const designComponent = await this.getDesignComponent();
    this.setState(
      {
        defaultValues,
        odaName: selectedOdaJobId
          ? odaJobs.byId[selectedOdaJobId].name
          : this.getDefaultODAName(),
        polling: selectedOdaJobId !== null,
        designComponent
      },
      () => {
        this.getOdaJobProgress();
      }
    );
  }

  async getOdaJobProgress() {
    const { polling } = this.state,
      { selectedOdaJobId, getOdaJobProgressAction, odaJobs } = this.props;
    if (selectedOdaJobId) {
      try {
        const selectedOdaJob = selectedOdaJobId
          ? odaJobs.byId[selectedOdaJobId]
          : null;
        const oldProgress = selectedOdaJob?.progress;
        const data = await getOdaJobProgressAction(selectedOdaJobId);
        if (oldProgress !== data.progress) {
          this.setState(
            {
              polling: false
            },
            () => {
              this.setState({
                polling: true
              });
            }
          );
        }
        if (
          data.status === "ERROR" ||
          data.status === "DONE" ||
          data.status === "STOPPED" ||
          data.status === "FAILED"
        ) {
          this.setState({ polling: false });
        } else if (polling) {
          this.getOdaJobProgressWithDelay();
        }
      } catch (exception) {
        console.log("progress exception", exception);
        if (polling) {
          this.setState({ polling: false });
        }
      }
    } else {
      this.setState({ polling: false });
    }
  }

  getOdaJobProgressWithDelay = debounce(() => {
    this.getOdaJobProgress();
  }, 5000);

  bindOdaFormSubmitter = formSubmitter => {
    this.odaFormSubmitter = formSubmitter;
  };

  validateForm = submissionMode => {
    this.setState({ submissionMode });
    this.odaFormSubmitter();
  };

  getDesignJobsList = designJobs => {
    const { openMetaComponentId } = this.props;
    return Object.values(designJobs.byId).filter(
      job =>
        job.meta_component === openMetaComponentId &&
        (job.status === "ERROR" || job.status === "DONE")
    );
  };

  async getdefaultValues() {
    const { selectedDesignJobId } = this.props;
    if (selectedDesignJobId) {
      const targets = await DesignApi.getDesignJobTargets(selectedDesignJobId);
      const nfwf = targets.nfwfs[0];
      const setPoint = targets.set_points[0];
      const {
          incident_light_type,
          incident_light,
          diffractive_order,
          unit
        } = setPoint,
        {
          azimut,
          zenit,
          wavelength,
          amplitude,
          polarization,
          focal_spot_x,
          focal_spot_y,
          focal_spot_z,
          beam_divergence
        } = incident_light,
        { X, Y } = diffractive_order,
        { polarization_out } = nfwf;
      return {
        azimut,
        zenit,
        wavelength,
        amplitude,
        polarization,
        polarization_out,
        incident_light_type,
        X,
        Y,
        unit,
        focal_spot_x,
        focal_spot_y,
        focal_spot_z,
        beam_divergence,
        order_x: 0,
        order_y: 0,
        direction_out: "Transmission"
      };
    }
    return null;
  }

  getDefaultODAName() {
    const { selectedDesignJobId, odaJobs } = this.props;
    const designOdaJobs = Object.values(odaJobs.byId).filter(
      job => odaJobs.design_job === selectedDesignJobId
    );
    return `ODA ${designOdaJobs.length + 1}`;
  }

  getOdaJobsList = odaJobs => {
    const { selectedDesignJobId } = this.props;
    return Object.values(odaJobs.byId).filter(
      job => job.design_job === selectedDesignJobId
    );
  };

  getSetPoint(odaJob) {
    const {
      diffractive_order,
      incident_light,
      set_point_unit,
      polarization_out,
      order_out
    } = odaJob;
    return {
      diffractive_order,
      incident_light,
      polarization_out,
      order_out,
      unit: set_point_unit
    };
  }

  getOutgoingConditions(odaJob) {
    const { order_out, direction_out, polarization_out } = odaJob;
    return {
      order_out,
      direction_out,
      polarization_out
    };
  }

  getTempVariables() {
    return this.props.tempVariables.map(tempVariable => ({
      ...tempVariable,
      variableName: tempVariable.name,
      simulation: -1 // since sweep inputs requires a simulation id, -1 means that there is no simulationId
    }));
  }

  getUsedVariables(job) {
    if (job) {
      const { swept_variables } = job;
      if (swept_variables.length) {
        const jobstring = JSON.stringify(job);
        return swept_variables.filter(variable =>
          jobstring.includes(`"=${variable.name}"`)
        );
      }
    }
    return [];
  }

  onShowNewJobDialog = () => {
    const { showConfirmDialog } = this.props;
    const title = "New ODA Calculation";
    const message = `Are you sure you want to start a new oda calculation?`;
    const confirmAction = () => this.validateForm(OdaJobSubmissionMode.CREATE);
    showConfirmDialog(title, message, confirmAction, undefined, false);
  };

  onShowUpdateJobDialog = () => {
    const { showConfirmDialog } = this.props;
    const title = "Update ODA Calculation";
    const message = `Are you sure you want to rerun the oda calculation with the actual parameters?`;
    const confirmAction = () => this.validateForm(OdaJobSubmissionMode.UPDATE);
    showConfirmDialog(title, message, confirmAction, undefined, false);
  };

  showResultMetrics = () => {
    const { selectedOdaJobId, odaJobs } = this.props;
    this.setState({
      jsonToShow: {
        metrics: odaJobs.byId[selectedOdaJobId]?.metrics || {}
      }
    });
  };

  onExportOda = () => {
    this.setState({ exporting: true });
    const { selectedOdaJobId, odaJobs } = this.props;
    const odaJob = odaJobs.byId[selectedOdaJobId];
    return OdaApi.exportOdaJob(selectedOdaJobId)
      .then(data => {
        const url = window.URL.createObjectURL(
          new Blob([data], { type: "application/octet-stream" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${odaJob.name}.data.zip`);
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.log(error))
      .finally(() => this.setState({ exporting: false }));
  };

  setValuesReady = values => {
    this.setState({
      dataToSubmit: {
        ...this.state.dataToSubmit,
        ...values
      },
      valueSubmissionIsReady: true
    });
  };

  onShowResultsErrorsAndWarnings = errors => {
    this.setState({
      jsonToShow: {
        errors
      }
    });
  };

  hideJsonDialog = () => {
    this.setState({ jsonToShow: null });
  };

  stopOdaJob = jobId => {
    return OdaApi.stopOdaJob(jobId).then(response => {
      this.getOdaJobProgress();
      return Promise.resolve();
    });
  };

  async getDesignComponent() {
    const { selectedDesignJobId } = this.props;
    if (selectedDesignJobId) {
      const data = await DesignApi.getDesignJobTargets(selectedDesignJobId);
      return data.meta_component;
    }
    return null;
  }

  render() {
    const {
        classes,
        designJobs,
        odaJobs,
        setPoints,
        selectedOdaJobId
      } = this.props,
      {
        polling,
        jsonToShow,
        message,
        defaultValues,
        designComponent
      } = this.state,
      designJobsList = this.getDesignJobsList(designJobs),
      odaJobsList = this.getOdaJobsList(odaJobs),
      selectedOdaJob = selectedOdaJobId ? odaJobs.byId[selectedOdaJobId] : null,
      setPoint = selectedOdaJobId ? this.getSetPoint(selectedOdaJob) : null,
      outgoingConditions = selectedOdaJob
        ? this.getOutgoingConditions(selectedOdaJob)
        : null,
      sweptVariables = selectedOdaJob ? selectedOdaJob.swept_variables : [];
    return (
      <div>
        <div className={classes.wrapper}>
          {polling && (
            <div className={classes.progress}>
              {selectedOdaJob && (
                <Typography>
                  ODA calculation status: {selectedOdaJob.status}
                </Typography>
              )}
              <div className={classes.wrapper}>
                <Spinner name="Waiting" size={68} timeout={180000} />
              </div>
              <div className={classes.wrapper}>
                {selectedOdaJob && (
                  <Typography>{`${selectedOdaJob.progress} %`}</Typography>
                )}
              </div>
              <br />
              {selectedOdaJob && (
                <>
                  <LinearProgress
                    name="ODAProgress"
                    variant="determinate"
                    value={selectedOdaJob.progress && selectedOdaJob.progress}
                  />
                  {(selectedOdaJob.status === "RUNNING" ||
                    selectedOdaJob.status === "QUEUED") && (
                    <div
                      style={{
                        textAlign: "center",
                        position: "relative",
                        marginTop: 20
                      }}
                    >
                      <Button
                        test-data="stopBtn"
                        name="stopODAButton"
                        variant="contained"
                        onClick={() => this.stopOdaJob(selectedOdaJob.id)}
                      >
                        Stop ODA Calculation
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          {!polling && (
            <div>
              <div>
                {selectedOdaJob && selectedOdaJob.status === "DONE" && (
                  <OdaJobResult
                    jobId={selectedOdaJob.id}
                    sweptVariables={this.getUsedVariables(selectedOdaJob)}
                    setPoint={setPoint}
                    outgoingConditions={outgoingConditions}
                    width={selectedOdaJob.width}
                    height={selectedOdaJob.height}
                    unit={designComponent ? designComponent.unit : "a.u."}
                  />
                )}
              </div>
              <div className={classes.left}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          className={classes.nameInput}
                          name="name"
                          label="Name"
                          value={this.state.odaName}
                          onChange={e =>
                            this.setState({ odaName: e.target.value })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={3}>
                      {defaultValues && (
                        <ODAForm
                          setPoint={setPoint}
                          isEditing={true}
                          bindResetForm={() => {}}
                          bindSubmitForm={this.bindOdaFormSubmitter}
                          isSweep
                          sweptVariables={this.getTempVariables()}
                          onSubmit={this.setValuesReady}
                          defaultValues={defaultValues}
                          disableAccuracy={true}
                          outgoingConditions={outgoingConditions}
                        />
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.right}>
                <div>
                  <DesignJobDropdown designJobs={designJobsList} />
                </div>
                <div>
                  <OdaJobDropdown odaJobs={odaJobsList} />
                </div>
                <div>
                  <JobActions
                    jobType={"ODA"}
                    onNewJob={this.onShowNewJobDialog}
                    showUpdateJobDialog={
                      selectedOdaJob && this.onShowUpdateJobDialog
                    }
                    exportAnalysis={
                      selectedOdaJob &&
                      selectedOdaJob.status !== "STOPPED" &&
                      this.onExportOda
                    }
                    exporting={this.state.exporting}
                    showResultsErrorsAndWarnings={
                      selectedOdaJob &&
                      selectedOdaJob.errors &&
                      (() =>
                        this.onShowResultsErrorsAndWarnings(
                          selectedOdaJob.errors
                        ))
                    }
                    errors={selectedOdaJob?.errors}
                    showResultMetrics={
                      selectedOdaJob ? this.showResultMetrics : null
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <JsonDialog
          open={jsonToShow !== null}
          data={jsonToShow}
          onClose={this.hideJsonDialog}
        />
        {message && <DirectionSnackbar message={message} />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    openMetaComponentId: DirectoryExplorerSelector.getMetaComponentOpenId(
      state
    ),
    designJobs: DesignSelector.getDesignJobs(state),
    selectedDesignJobId: DesignSelector.getSelectedJobId(state),
    selectedOdaJobId: ODASelector.getSelectedJobId(state),
    tempVariables: ODASelector.getTempSweptVariables(state),
    odaJobs: ODASelector.getOdaJobs(state)
    //   setPoints: SetPointSelector.getSetPoints(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createOdaJob: designJobId => dispatch(OdaApi.start(designJobId)),
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      ),
    selectOdaJob: jobId => dispatch(OdaAction.selectJob(jobId)),
    getOdaJobProgressAction: jobId => dispatch(OdaApi.getOdaJobProgress(jobId)),
    updateOdaJob: (odaJobId, properties) =>
      dispatch(OdaApi.restart(odaJobId, properties))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(ODACanvas)));
