import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "components/Spinner/Spinner";
import ExportReportMenu from "MetaCell/containers/SimulateCanvas/components/ExportReportMenu";
import ErrorBadge from "components/ErrorBadge/ErrorBadge";

const useStyles = makeStyles(theme => ({
  right: {
    display: "flex",
    flexDirection: "column"
  },
  buttonMargin: {
    marginBottom: "15px"
  }
}));

/**
 * A component to show action buttons for jobs
 * @param {Object} props - the object properties
 * @return {Component}
 */

const JobActions = props => {
  const classes = useStyles();
  return (
    <div className={classes.right}>
      <Tooltip title={`Start a new ${props.jobType}`}>
        <Button
          test-data="startButton"
          className={classes.buttonMargin}
          variant="contained"
          color="primary"
          onClick={props.onNewJob}
        >
          New {props.jobType}
        </Button>
      </Tooltip>
      {props.showUpdateJobDialog && (
        <Tooltip
          title={`Update the currently selected analysis ${props.jobType}`}
        >
          <Button
            test-data="updateButton"
            className={classes.buttonMargin}
            variant="contained"
            onClick={props.showUpdateJobDialog}
          >
            Update {props.jobType}
          </Button>
        </Tooltip>
      )}
      {props.exportAnalysis && (
        <>
          <Tooltip title={`Export the raw selected ${props.jobType}`}>
            <div>
              <Button
                test-data="exportButton"
                className={classes.buttonMargin}
                variant="contained"
                onClick={props.exportAnalysis}
                disabled={props.exporting}
              >
                {props.exporting && (
                  <Spinner
                    name="Waiting"
                    size={24}
                    style={{ marginRight: 10 }}
                    timeout={30000}
                  />
                )}
                Export raw data
              </Button>
            </div>
          </Tooltip>
        </>
      )}
      {props.showResultsErrorsAndWarnings && (
        <Button
          test-data="errorsButton"
          name="Errors/Warnings"
          className={classes.buttonMargin}
          variant="contained"
          onClick={props.showResultsErrorsAndWarnings}
        >
          <ErrorBadge
            errors={props.errors}
            warnings={props.warnings}
            buttonLabel="Errors/Warnings"
          />
        </Button>
      )}
      {props.exportSimulation && (
        <ExportReportMenu
          simulationName={props.simulationName}
          projectName={props.projectName}
          username={props.username}
          configuration={props.configuration}
          simulationJobId={props.simulationJobId}
          btnClassName={classes.buttonMargin}
          exportPlotReport={props.exportPlotReport}
          exportConfigurationReport={props.exportConfigurationReport}
          exportFullReport={props.exportFullReport}
        />
      )}
      {props.extraButtons?.map(button => (
        <Button
          test-data={button.testData}
          name={button.name}
          className={classes.buttonMargin}
          variant="contained"
          onClick={button.onClick}
          disabled={button.disabled}
        >
          {button.status && (
            <Spinner
              name="Waiting"
              size={24}
              style={{ marginRight: 10 }}
              timeout={30000}
            />
          )}
          {button.name}
        </Button>
      ))}
      {props.showResultMetrics && (
        <Button
          test-data="metricsButton"
          name="Metrics"
          className={classes.buttonMargin}
          variant="contained"
          onClick={props.showResultMetrics}
        >
          Metrics
        </Button>
      )}
    </div>
  );
};

export default JobActions;
