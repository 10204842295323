import React, { PureComponent } from "react";
import { Grid, Typography } from "@material-ui/core";
import GenericHeatmap from "components/Heatmap/Heatmap";
import ContainerDimensions from "react-container-dimensions";
import PlotboxHelper from "MetaCell/helper/Plotbox";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ImportIcon from "@material-ui/icons/CloudUpload";
import Helper from "MetaComponent/helper/FFWFTarget";
import WavefrontGenerator from "./components/WavefrontGenerator/WavefrontGenerator";
import Import from "components/Import";
import BrushIcon from "@material-ui/icons/Brush";
import DeleteIcon from "@material-ui/icons/Delete";
import LoadingOverlay from "components/LoadingOverlay/LoadingOverlay";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import JobStatusAndProgress from "components/JobStatusAndProgress/JobStatusAndProgress";

export const wavefrontTypes = Object.freeze({
  AMPLITUDE: "Amplitude",
  PHASE: "Phase"
});

export const wavefrontUnits = Object.freeze({
  AMPLITUDE: "V/m",
  PHASE: "rad"
});

/**
 * a component to show the wavefront of a ffwf target
 * @author Akira Kotsugai
 */
export class Wavefront extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editingWavefront: null,
      wfGeneratorOpen: false,
      importDialogOpen: false,
      processing: false
    };
  }

  /**
   * @returns {Number[][]} - the displayable wavefront data
   */
  getZData(selectedWavefrontType) {
    const { wavefront } = this.props;
    return Helper.getWavefrontZData(wavefront, selectedWavefrontType);
  }

  /**
   * @returns {Number[]} the wf displayable X data
   */
  getXData(selectedWavefrontType) {
    const { wfWidth, xCenter } = this.props,
      zData = this.getZData(selectedWavefrontType);
    return Helper.getWavefrontXData(zData, wfWidth, xCenter);
  }

  /**
   * @returns {Number[]} the wf displayable Y data
   */
  getYData(selectedWavefrontType) {
    const { wfHeight, yCenter } = this.props,
      zData = this.getZData(selectedWavefrontType);
    return Helper.getWavefrontYData(zData, wfHeight, yCenter);
  }

  /**
   * @returns {Object[][]} it makes drilldown objects out of the wavefront types
   */
  getDrilldownOptions() {
    return [
      Object.values(wavefrontTypes).map(wfType => ({
        text: wfType,
        isSelected: false
      }))
    ];
  }

  /**
   * it tells the state that the import dialog is closed.
   */
  handleClose = () => {
    const { onResetFileError } = this.props;
    if (onResetFileError) onResetFileError();
    this.setState({ importDialogOpen: false });
  };

  /**
   * it tells the state that the impor dialog is open.
   */
  openImportDialog = () => {
    this.setState({ importDialogOpen: true });
  };

  /**
   * it calls the on change callback with the dropped file and closes the import dialog
   * @param { File[] } acceptedFiles - an array containing the dropped file
   */
  handleFileDrop = async acceptedFiles => {
    const { onResetFileError } = this.props;
    if (acceptedFiles.length > 0) {
      if (onResetFileError) onResetFileError();
      this.setState({ processing: true });
      const wavefrontFile = acceptedFiles[0];
      this.props.onChange(
        wavefrontFile,
        () => {
          this.setState({ processing: false });
          this.handleClose();
        },
        () => {
          this.setState({ processing: false });
        }
      );
    }
  };

  /**
   * it tells the state that wf generator dialog is closed.
   */
  closeWFGenerator = () => {
    this.setState({ wfGeneratorOpen: false });
  };

  /**
   * it tells the state that the wf generator dialog is open
   */
  openWFGenerator = () => {
    this.setState({ wfGeneratorOpen: true });
  };

  handleWavefrontGeneratorConfirm = wavefront => {
    this.setState({ processing: true });
    this.props.onGeneratorConfirm(wavefront, () =>
      this.setState({ processing: false })
    );
  };

  generateAsyncWavefront = (data, onSuccess, onError) => {
    this.setState({ processing: true });
    this.props.onGenerateAsyncWavefront(
      data,
      response => {
        this.setState({ processing: false });
      },
      onSuccess,
      error => {
        this.setState({ processing: false });
        onError(error);
      }
    );
  };

  handleDelete = () => {
    this.setState({ processing: true });
    this.props.onDelete(() => {
      this.setState({ processing: false });
    });
  };

  render() {
    const {
      onlyAmplitude,
      rowsCount,
      columnsCount,
      crossSectionOrientation,
      crossSectionIndex,
      handleCrossSection,
      fileError
    } = this.props;
    return (
      <>
        <Import
          open={this.state.importDialogOpen}
          onClose={this.handleClose}
          onDrop={this.handleFileDrop}
          error={fileError}
          loading={this.state.processing}
          message="Drag and drop your json file here, or click to select file. For large files (e.g. > 100 MB), please use .json format."
          accept={"application/json, .msgpack, .zip"}
        />
        {this.props.onGeneratorConfirm && (
          <WavefrontGenerator
            open={this.state.wfGeneratorOpen}
            onClose={this.closeWFGenerator}
            onConfirm={this.handleWavefrontGeneratorConfirm}
            onAsyncGenerate={
              this.props.onGenerateAsyncWavefront
                ? this.generateAsyncWavefront
                : null
            }
            onlyAmplitude={this.props.onlyAmplitude}
            sampleScripts={this.props.sampleScripts}
            componentWidth={this.props.componentWidth}
            componentHeight={this.props.componentHeight}
            componentUnit={this.props.componentUnit}
            wfWidth={this.props.wfWidth}
            wfHeight={this.props.wfHeight}
            wfUnit={this.props.unit}
            cellWidth={this.props.cellWidth}
            cellHeight={this.props.cellHeight}
            cellUnit={this.props.cellUnit}
            fixedShape={!this.props.onlyAmplitude}
            scriptId={this.props.scriptId}
          />
        )}
        <div style={{ width: "100%", position: "relative" }}>
          {(this.state.processing || this.props.polling) && !fileError && (
            <JobStatusAndProgress
              jobType={"Generating Wavefront Images"}
              jobStatus={this.props.jobStatus}
              showBigSpinner={this.props.jobStatus !== "STOPPED"}
              stop={this.props.stopWavefrontGeneration}
              showStartButton={false}
              polling={this.props.polling}
              starting={
                (this.state.processing || this.props.polling) &&
                (this.props.jobStatus === "IDLE" ||
                  this.props.jobStatus === "DONE")
              }
              showProgress={false}
              showStatus={this.props.jobStatus !== "DONE"}
            />
          )}
          {!(this.state.processing || this.props.polling) &&
            this.props.showStaticRepresentation &&
            !this.props.staticWavefront && <LoadingOverlay />}
          {!(this.state.processing || this.props.polling) && (
            <Grid container spacing={2}>
              <Grid item xs={!onlyAmplitude ? 10 : 12}>
                <div style={{ width: "100%", display: "flex" }}>
                  <div style={{ flexGrow: 1 }} />
                  {this.props.showStaticRepresentation &&
                    this.props.staticWavefront &&
                    this.props.staticWavefront.amplitude &&
                    this.props.staticWavefront.phase && (
                      <Tooltip
                        title="Open zoom-able image (beware this may take time and slow down navigation)"
                        placement="top"
                      >
                        <IconButton
                          test-data="interactButton"
                          onClick={this.props.toggleInteraction}
                        >
                          <TouchAppIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  {this.props.onDelete && (
                    <Tooltip title="delete wavefront" placement="top">
                      <IconButton
                        test-data="deleteButton"
                        onClick={this.handleDelete}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {this.props.onGeneratorConfirm && (
                    <Tooltip title="design wavefront" placement="top">
                      <IconButton
                        test-data="designButton"
                        onClick={this.openWFGenerator}
                      >
                        <BrushIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {this.props.onChange && (
                    <Tooltip title="Import wavefront" placement="top">
                      <IconButton
                        test-data="importButton"
                        onClick={this.openImportDialog}
                      >
                        <ImportIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {this.props.onExport && (
                    <Tooltip title="Export wavefront" placement="top">
                      <IconButton
                        test-data="exportButton"
                        onClick={this.props.onExport}
                        disabled={this.props.exportLoading}
                      >
                        <SaveAltIcon />
                        {this.props.exportLoading ? (
                          <CircularProgress size={20} />
                        ) : null}
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </Grid>
              <>
                <Grid item xs={!onlyAmplitude ? 5 : 12}>
                  {!onlyAmplitude && (
                    <>
                      <h4 style={{ marginBottom: 0 }}>Amplitude</h4>
                      {((!this.props.showStaticRepresentation &&
                        !this.props.wavefront) ||
                        (this.props.showStaticRepresentation &&
                          (!this.props.staticWavefront ||
                            !this.props.staticWavefront.amplitude))) && (
                        <Typography variant="caption">
                          {"There is no data for wavefront amplitude."}
                        </Typography>
                      )}
                    </>
                  )}

                  {this.props.showStaticRepresentation &&
                    this.props.staticWavefront &&
                    this.props.staticWavefront.amplitude && (
                      <ContainerDimensions>
                        {({ width }) => {
                          return (
                            <img
                              test-data="staticAmplitude"
                              src={`data:image/jpeg;base64,${this.props.staticWavefront.amplitude}`}
                              alt=""
                              style={{
                                alignSelf: "center",
                                marginLeft: width * 0.1
                              }}
                              width={width * 0.8}
                              height={width * 0.8}
                            />
                          );
                        }}
                      </ContainerDimensions>
                    )}

                  {!this.props.showStaticRepresentation &&
                    this.props.wavefront &&
                    this.props.wfHeight &&
                    this.props.wfWidth && (
                      <ContainerDimensions>
                        {({ width }) => {
                          const xData = this.getXData(wavefrontTypes.AMPLITUDE),
                            yData = this.getYData(wavefrontTypes.AMPLITUDE),
                            zData = this.getZData(wavefrontTypes.AMPLITUDE),
                            heatmapWidth = PlotboxHelper.getHeatmapWidth(
                              width,
                              xData,
                              yData
                            ),
                            heatmapHeight = PlotboxHelper.getHeatmapHeight(
                              width,
                              xData,
                              yData
                            );
                          return (
                            <div
                              style={{
                                backgroundColor: "white",
                                position: "relative",
                                marginLeft: (width - heatmapWidth) / 2
                              }}
                            >
                              <GenericHeatmap
                                width={heatmapWidth}
                                height={heatmapHeight}
                                x={xData}
                                y={yData}
                                z={zData}
                                xLabel={
                                  this.props.unit &&
                                  `${
                                    this.props.xLabel
                                      ? this.props.xLabel
                                      : "width"
                                  } (${this.props.unit})`
                                }
                                yLabel={
                                  this.props.unit &&
                                  `${
                                    this.props.yLabel
                                      ? this.props.yLabel
                                      : "length"
                                  } (${this.props.unit})`
                                }
                                showLegend={this.props.showLegend || false}
                                valueUnit={
                                  !onlyAmplitude && wavefrontUnits.AMPLITUDE
                                }
                                enableCrossSection={
                                  this.props.enableCrossSection
                                }
                                crossSectionRowsCount={
                                  rowsCount ? rowsCount : xData.length
                                }
                                crossSectionColumnsCount={
                                  columnsCount ? columnsCount : yData.length
                                }
                                crossSectionOrientation={
                                  crossSectionOrientation
                                }
                                crossSection3DUnit={this.props.unit}
                                crossSectionIndex={crossSectionIndex}
                                crossSectionColorbarUnit={"Amp(V/m)"}
                                handleCrossSection={(
                                  cross_section_orientation,
                                  cross_section_index
                                ) =>
                                  handleCrossSection(
                                    cross_section_orientation,
                                    cross_section_index,
                                    "Amplitude"
                                  )
                                }
                              />
                            </div>
                          );
                        }}
                      </ContainerDimensions>
                    )}
                </Grid>
                {!onlyAmplitude && (
                  <Grid item xs={5}>
                    <h4 style={{ marginBottom: 0 }}>Phase</h4>
                    {((!this.props.showStaticRepresentation &&
                      !this.props.wavefront) ||
                      (this.props.showStaticRepresentation &&
                        (!this.props.staticWavefront ||
                          !this.props.staticWavefront.phase))) && (
                      <Typography variant="caption">
                        {"There is no data for wavefront phase."}
                      </Typography>
                    )}

                    {this.props.showStaticRepresentation &&
                      this.props.staticWavefront &&
                      this.props.staticWavefront.phase && (
                        <ContainerDimensions>
                          {({ width }) => {
                            return (
                              <img
                                test-data="staticPhase"
                                src={`data:image/jpeg;base64,${this.props.staticWavefront.phase}`}
                                alt=""
                                style={{
                                  alignSelf: "center",
                                  marginLeft: width * 0.1
                                }}
                                width={width * 0.8}
                                height={width * 0.8}
                              />
                            );
                          }}
                        </ContainerDimensions>
                      )}

                    {!this.props.showStaticRepresentation &&
                      this.props.wavefront &&
                      this.props.wfHeight &&
                      this.props.wfWidth && (
                        <ContainerDimensions>
                          {({ width }) => {
                            const xData = this.getXData(wavefrontTypes.PHASE),
                              yData = this.getYData(wavefrontTypes.PHASE),
                              zData = this.getZData(wavefrontTypes.PHASE),
                              heatmapWidth = PlotboxHelper.getHeatmapWidth(
                                width,
                                xData,
                                yData
                              ),
                              heatmapHeight = PlotboxHelper.getHeatmapHeight(
                                width,
                                xData,
                                yData
                              );
                            return (
                              <div
                                style={{
                                  backgroundColor: "white",
                                  position: "relative",
                                  marginLeft: (width - heatmapWidth) / 2
                                }}
                              >
                                <GenericHeatmap
                                  width={heatmapWidth}
                                  height={heatmapHeight}
                                  x={xData}
                                  y={yData}
                                  z={zData}
                                  showLegend={this.props.showLegend || false}
                                  valueUnit={wavefrontUnits.PHASE}
                                  xLabel={
                                    this.props.unit &&
                                    `${
                                      this.props.xLabel
                                        ? this.props.xLabel
                                        : "width"
                                    } (${this.props.unit})`
                                  }
                                  yLabel={
                                    this.props.unit &&
                                    `${
                                      this.props.yLabel
                                        ? this.props.yLabel
                                        : "length"
                                    } (${this.props.unit})`
                                  }
                                  enableCrossSection={
                                    this.props.enableCrossSection
                                  }
                                  crossSectionRowsCount={
                                    rowsCount ? rowsCount : xData.length
                                  }
                                  crossSectionColumnsCount={
                                    columnsCount ? columnsCount : yData.length
                                  }
                                  crossSectionOrientation={
                                    crossSectionOrientation
                                  }
                                  crossSection3DUnit={this.props.unit}
                                  crossSectionIndex={crossSectionIndex}
                                  crossSectionColorbarUnit={"Phase(rad)"}
                                  handleCrossSection={(
                                    cross_section_orientation,
                                    cross_section_index
                                  ) =>
                                    handleCrossSection(
                                      cross_section_orientation,
                                      cross_section_index,
                                      "Phase"
                                    )
                                  }
                                />
                              </div>
                            );
                          }}
                        </ContainerDimensions>
                      )}
                  </Grid>
                )}
              </>
            </Grid>
          )}
        </div>
        {this.props.showImportFailed && (
          <DirectionSnackbar message="Failed to import wavefront." />
        )}
      </>
    );
  }
}

export default Wavefront;
