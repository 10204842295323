import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Link } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  typography: {
    textAlign: "center",
    marginBottom: "1.5rem"
  }
}));

const WaitingPage = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <div>
        <Typography variant="h3" component="h1" className={classes.typography}>
          PlanOpSim is loading! 🚀
        </Typography>
        <div>
          <Typography variant="body1">
            Please wait. This may take a few minutes. If you have any questions,
            contact us at{" "}
            <Link href="mailto:support@planopsim.com" color="primary">
              support@planopsim.com
            </Link>
            .
          </Typography>
          <Typography variant="body1">
            —{" "}
            <Link
              href="https://planopsim.com/"
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
            >
              PlanOpSim
            </Link>{" "}
            Team
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default WaitingPage;
